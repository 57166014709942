// extracted by mini-css-extract-plugin
export var IconLink = "recruit__IconLink-wB7gz";
export var col = "recruit__col-A1Qde";
export var contact = "recruit__contact-Q6ldT";
export var contact__message = "recruit__contact__message-VmhV9";
export var definition = "recruit__definition-KcHam";
export var definition__description = "recruit__definition__description-p3+i1";
export var definition__term = "recruit__definition__term-jsETs";
export var heading = "recruit__heading-Ebr81";
export var hero = "recruit__hero-cExF-";
export var hero__message = "recruit__hero__message-YHSO+";
export var hero__title = "recruit__hero__title-TGMDC";
export var highlight = "recruit__highlight-XJ33D";
export var jump = "recruit__jump-GUkZj";
export var main = "recruit__main-N1R2n";
export var mainheading = "recruit__mainheading-mRGam";
export var moreLink = "recruit__moreLink-6iko0";
export var nav = "recruit__nav-gB2Gn";
export var navLink = "recruit__navLink-sAJQX";
export var panelMoreLink = "recruit__panelMoreLink-iy2NK";
export var row = "recruit__row-UrY5n";
export var section = "recruit__section-fuJ9q";
export var section__voicecard = "recruit__section__voicecard-w3n10";
export var voicecard = "recruit__voicecard-FTgRu";
export var voicecard__card = "recruit__voicecard__card-AA5o0";
export var voicecard__title = "recruit__voicecard__title-DU41u";
export var voicecard__txtbox = "recruit__voicecard__txtbox-UydSN";
export var voicecard__voice = "recruit__voicecard__voice-tRiWn";